<template>
  <div>
    <nav
      v-if="isLoggedIn && isInitiated"
      class="navbar-custom"
    >
      <router-link
        :to="{ name: 'Dashboard'}"
        class="navbar-item"
      >
        <span class="icon is-small"><i class="fas fa-chevron-left"></i></span>
        <span>Passport</span>
      </router-link>
    </nav>
    <hr class="separation" />
    <div class="has-background-white has-padding">
      <h3 class="title is-3">People and locations</h3>
      <div class="maps-container">
        <visualisation-map
          ref="visualisationMap"
          :mapHeight="'75vh'"
          class="maps"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VisualisationMap from '@/components/visualisationMap/VisualisationMap'
export default {
  name: 'Network',

  components: {
    'visualisation-map': VisualisationMap
  },

  props: {},

  data() {
    return {
    }
  },

  computed: {
    ...mapGetters({
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn'
    })
  },

  created() { },

  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
